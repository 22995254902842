import { Button, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import useRegisterNfcUsb from "src/hooks/useRegisterNfcUsb";

import NFCSvg from "@app-components/svg/NFCSvg";
import { IItem } from "@app-interface/item";

export default function FindRingModal({
  isOpen,
  onSuccess,
  onCancel,
}: {
  isOpen?: boolean;
  itemData?: IItem | null;
  onSuccess?: (uid: string) => void;
  onCancel?: () => void;
}) {
  const { uidList, resetUidList } = useRegisterNfcUsb();
  const [form] = Form.useForm();
  const [currentIndex, setCurrentIndex] = useState<number | null>(0);

  useEffect(() => {
    setCurrentIndex(0);

    return () => {
      form?.resetFields();
      resetUidList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (currentIndex !== uidList.length && uidList.length > 0) {
      setCurrentIndex(uidList.length);
      onSuccess?.(uidList[uidList.length - 1] ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidList]);

  const handleFinish = async (values: any) => {
    console.log(values);

    onSuccess?.(values.uid);
    onCancel?.();
  };

  return (
    <Modal
      title={"Find The Ring"}
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      className="w-[90%] md:w-[60%]"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        initialValues={{ uid: "" }}
      >
        <Form.Item
          className="flex-1"
          label="Enter UID or Scan The Ring"
          name="uid"
        >
          <Input className="w-full" placeholder="Input Ring UID" />
        </Form.Item>
        <div className="flex flex-col gap-2 justify-center items-center py-10">
          <NFCSvg />
          <div className="pt-6 text-xl font-semibold">Scan ring to search</div>
        </div>

        <Form.Item className="flex justify-end">
          <Button onClick={onCancel} className="mr-4">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
