import React from "react";

export default function NFCSvg({ color = "#111318" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="127"
      height="127"
      viewBox="0 0 127 127"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.5 85C125.433 85 127 86.567 127 88.5V106C127 111.57 124.788 116.911 120.849 120.849C116.911 124.788 111.57 127 106 127H88.5C86.567 127 85 125.433 85 123.5C85 121.567 86.567 120 88.5 120H106C109.713 120 113.274 118.525 115.899 115.899C118.525 113.274 120 109.713 120 106V88.5C120 86.567 121.567 85 123.5 85Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85 3.5C85 1.567 86.567 0 88.5 0H106C111.57 0 116.911 2.21249 120.849 6.15076C124.788 10.089 127 15.4305 127 21V38.5C127 40.433 125.433 42 123.5 42C121.567 42 120 40.433 120 38.5V21C120 17.287 118.525 13.726 115.899 11.1005C113.274 8.475 109.713 7 106 7H88.5C86.567 7 85 5.433 85 3.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 85C5.433 85 7 86.567 7 88.5V106C7 109.713 8.475 113.274 11.1005 115.899C13.726 118.525 17.287 120 21 120H38.5C40.433 120 42 121.567 42 123.5C42 125.433 40.433 127 38.5 127H21C15.4305 127 10.089 124.788 6.15076 120.849C2.21249 116.911 0 111.57 0 106V88.5C0 86.567 1.567 85 3.5 85Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7C17.287 7 13.726 8.475 11.1005 11.1005C8.475 13.726 7 17.287 7 21V38.5C7 40.433 5.433 42 3.5 42C1.567 42 0 40.433 0 38.5V21C0 15.4305 2.2125 10.089 6.15076 6.15076C10.089 2.2125 15.4305 0 21 0H38.5C40.433 0 42 1.567 42 3.5C42 5.433 40.433 7 38.5 7H21Z"
      />
      <path d="M65.0001 59.7498C67.3933 59.7498 69.3334 61.6899 69.3334 64.0832C69.3334 66.4764 67.3933 68.4165 65.0001 68.4165C62.6068 68.4165 60.6667 66.4764 60.6667 64.0832C60.6667 61.6899 62.6068 59.7498 65.0001 59.7498Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6443 74.3909C56.4914 73.6272 56.568 72.3112 55.8154 71.4515C53.9072 69.2717 52.8533 66.4573 52.8533 63.5415C52.8533 60.6257 53.9072 57.8113 55.8154 55.6315C56.568 54.7718 56.4914 53.4558 55.6443 52.6921C54.7972 51.9283 53.5005 52.0061 52.7479 52.8658C50.1725 55.8077 48.75 59.6062 48.75 63.5415C48.75 67.4768 50.1725 71.2753 52.7479 74.2172C53.5005 75.0769 54.7972 75.1547 55.6443 74.3909Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.3707 74.3909C74.3388 75.1547 75.8208 75.0769 76.6809 74.2172C79.6242 71.2753 81.2499 67.4768 81.2499 63.5415C81.2499 59.6062 79.6242 55.8077 76.6809 52.8658C75.8208 52.0061 74.3388 51.9283 73.3707 52.6921C72.4026 53.4558 72.3151 54.7718 73.1751 55.6315C75.3559 57.8113 76.5605 60.6257 76.5605 63.5415C76.5605 66.4573 75.3559 69.2717 73.1751 71.4515C72.3151 72.3112 72.4026 73.6272 73.3707 74.3909Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.0187 81.9182C48.9489 81.1038 48.9982 79.7373 48.1287 78.8661C43.8336 74.5623 41.4443 68.8911 41.4443 63C41.4443 57.1089 43.8336 51.4377 48.1287 47.1339C48.9982 46.2627 48.9489 44.8962 48.0187 44.0818C47.0885 43.2675 45.6295 43.3136 44.7601 44.1848C39.6666 49.2886 36.8333 56.0139 36.8333 63C36.8333 69.9861 39.6666 76.7114 44.7601 81.8152C45.6295 82.6864 47.0885 82.7325 48.0187 81.9182Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8315 81.9182C81.6771 82.7325 83.0034 82.6864 83.7939 81.8152C88.4243 76.7114 91.0001 69.9861 91.0001 63C91.0001 56.0139 88.4243 49.2886 83.7939 44.1848C83.0034 43.3136 81.6771 43.2675 80.8315 44.0818C79.9858 44.8962 79.9411 46.2627 80.7315 47.1339C84.6361 51.4377 86.8082 57.1089 86.8082 63C86.8082 68.8911 84.6361 74.5623 80.7315 78.8661C79.9411 79.7373 79.9858 81.1038 80.8315 81.9182Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.5264 88.4591C90.3808 89.2426 91.6915 89.1659 92.4538 88.2876C98.4947 81.3278 101.833 72.3269 101.833 63C101.833 53.6731 98.4947 44.6722 92.4538 37.7124C91.6915 36.8341 90.3808 36.7574 89.5264 37.5409C88.6719 38.3245 88.5972 39.6716 89.3595 40.5499C94.7225 46.7287 97.6865 54.7196 97.6865 63C97.6865 71.2804 94.7225 79.2713 89.3595 85.4501C88.5972 86.3284 88.6719 87.6755 89.5264 88.4591Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3325 88.4591C40.2581 87.6755 40.3391 86.3284 39.5132 85.4501C33.7033 79.2713 30.4924 71.2804 30.4924 63C30.4924 54.7196 33.7033 46.7287 39.5132 40.5499C40.3391 39.6716 40.2581 38.3245 39.3325 37.5409C38.4068 36.7574 36.9869 36.8341 36.161 37.7124C29.6167 44.6722 26 53.6731 26 63C26 72.3269 29.6167 81.3278 36.161 88.2876C36.9869 89.1659 38.4068 89.2426 39.3325 88.4591Z"
      />
    </svg>
  );
}
