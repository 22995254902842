import { message } from "antd";
import { useEffect, useRef, useState } from "react";

const { ipcRenderer } = window.electron || {};

export default function useRegisterNfcUsb() {
  const cardRef = useRef<any>("");
  const [uidList, setUidList] = useState<string[]>([]);

  useEffect(() => {
    registerNfcUsb();

    return () => {
      resetUidList();
      if (ipcRenderer) {
        ipcRenderer.removeAllListeners();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerNfcUsb = () => {
    if (!ipcRenderer) return;
    ipcRenderer.on("fromMain", function (_: any, event: any) {
      if (event.card) {
        const newCardId = event.card.uid.toUpperCase();
        setUidList((prev) => [...prev, newCardId]);
        cardRef.current = newCardId;
      } else if (event.error) {
        message.error(event.error?.message);
      } else if (event.text) {
        message.info(event.text);
      }
    });
  };

  const resetUidList = () => {
    setUidList([] as string[]);
    cardRef.current = "";
  };

  return { uidList, resetUidList };
}
